import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Tooltip, Box, IconButton } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { colors } from "constants/themeConstants";
import {
  FormikDateField,
  FormikPhoneField,
  FormikTextField,
  FormikSelectWithImageField,
  FormikSelectField,
  FormikSelectScheduleField,
} from "components/formComponents/CustomFormikFields";
import {
  useGetPaymentMethodsQuery,
  useGetEventPaymentMethodsQuery,
} from "features/paymentPlan/paymentPlanApiSlice";
import { useGetEventQuery } from "features/events/eventsApiSlice";
import { useValidatePromoCodeQuery } from "features/promocode/promocodeApislice";
import { useGetEventsQuery } from "features/events/eventsApiSlice";
import useGetDataPagination from "hooks/useGetDataPagination";
import moment from "moment";

import { useGetEventScheduleQuery } from "features/events/eventsApiSlice";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import React, { useRef, useState, useEffect } from "react";

function PaymentForm({
  ticket,
  price,
  useEmail,
  setUseEmail,
  eventId,
  isFree,
  bundle,
  currency,
  isRecurring,
  specialService,
  setSnapshot,
}) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [checkpromocode, validatepromocode] = useState(false);

  useEffect(() => {}, [checkpromocode]);
  const handleInfoClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };
  const {
    data: eventPaymentMethodsData,
    isLoading: eventLoading,
    isError: eventError,
  } = useGetEventPaymentMethodsQuery(eventId);
  const {
    isLoading: isLoadingSchedule,
    pagination: paginationSchedule,
    setPagination: setPaginationSchedule,
    rowCount: rowCountSchedule,
    data: schedule,
    setEventId: setEventIdSchedule,
  } = useGetDataPagination()(useGetEventScheduleQuery);
  useEffect(() => {
    setEventIdSchedule(eventId);
  }, []);

  console.log(schedule);
  const [promoCode, setPromoCode] = useState("");
  const { data: eventsRaw, isLoading: loading } = useGetEventQuery(eventId);
  console.log(eventsRaw, "the events");

  console.log(promoCode);
  const { data, isLoading: paymentLoading } = useGetPaymentMethodsQuery();
  const {
    data: promoCodeData,
    isLoading,
    isError,
    error,
  } = useValidatePromoCodeQuery({
    ticket_id: ticket,
    promo_code: promoCode,
  });

  let promoCodeDiscountRate = promoCodeData?.promo_code_discount_rate;

  const isPaymentMethodsVisible = promoCodeDiscountRate !== 1;

  const filteredPaymentMethods = data?.filter((method) =>
    method.supported_currencies.includes(currency)
  );
  const filteredPaymentMethods2 = eventPaymentMethodsData?.filter((method) =>
    method.payment_method.supported_currencies.includes(currency)
  );
  console.log(filteredPaymentMethods2, "asdnilasdiuaisd asid");
  console.log(promoCode.length);

  let paymentMethods = [];
  if (filteredPaymentMethods2 && filteredPaymentMethods2.length !== 0) {
    paymentMethods = filteredPaymentMethods2.map((item) => ({
      label: item.payment_method.logo_url,
      value: item.payment_method.identifier,
      discountRate: item?.discount_rate,
    }));
  } else if (filteredPaymentMethods && filteredPaymentMethods.length !== 0) {
    paymentMethods = filteredPaymentMethods.map((item) => ({
      label: item.logo_url,
      value: item.identifier,
      discountRate: item?.discount_rate,
    }));
  }
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const contentRef = useRef(null);
  useEffect(() => {
    const handleResizeAndScroll = () => {
      const container = contentRef.current;
      if (container) {
        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(
          container.scrollWidth > container.clientWidth + container.scrollLeft
        );
      }
    };

    const container = contentRef.current;
    if (container) {
      handleResizeAndScroll();
      container.addEventListener("scroll", handleResizeAndScroll);
      window.addEventListener("resize", handleResizeAndScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleResizeAndScroll);
        window.removeEventListener("resize", handleResizeAndScroll);
      }
    };
  }, []);
  const handleScroll = (scrollOffset) => {
    const container = contentRef.current;
    if (container) {
      container.scrollTo({
        left: container.scrollLeft + scrollOffset,
        behavior: "smooth",
      });
    }
  };
  paymentMethods = paymentMethods?.sort((a, b) => {
    if (!a?.discountRate && !b.discountRate) {
      return 0;
    } else if (!a?.discountRate) {
      return 1;
    } else if (!b?.discountRate) {
      return -1;
    }
    return b?.discountRate - a?.discountRate;
  });

  return (
    <React.Fragment>
      {/* <Grid style={{ overflowX: "auto" }} ref={contentRef}>
        <Grid spacing={2} style={{ display: "inline-flex" }}>
          {showLeftArrow && (
            <IconButton
              style={{
                position: "absolute",
                left: 0,
                top: "40%",
                transform: "translateY(-50%)",
              }}
              onClick={() => handleScroll(-100)}
            >
              <ArrowBack />
            </IconButton>
          )}
          {schedule?.map((item, index) => (
            <Grid key={item.id} item xs="auto">
              <Box
                sx={{
                  padding: "1.5rem 1rem 1.5rem 1rem",
                  margin: "1rem",
                  width: "10rem",
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#C5C6C6",
                  borderRadius: "10px",
                  cursor: "pointer",

                  border: `"1px solid ${colors.background.secondary}`,
                  // p: 0.15,
                }}
              >
                <Typography
                  marginRight={1}
                  fontWeight={500}
                  color={colors.background.tertiary}
                  fontFamily="Campton"
                >
                  {item.name}
                </Typography>
                <Grid display="flex">
                  <Typography
                    marginRight={1}
                    fontWeight={600}
                    color={colors.background.tertiary}
                    fontFamily="Campton"
                  >
                    {moment(new Date(item?.start_date)).format("MMM")}
                  </Typography>
                  <Typography
                    color={colors.background.tertiary}
                    fontFamily="Campton"
                  >
                    {moment(new Date(item?.start_date)).format("DD,YYYY")}
                  </Typography>
                </Grid>
                <Typography
                  color={colors.background.tertiary}
                  fontFamily="Campton"
                >
                  {moment(new Date(`2000-01-01T${item.start_time}`)).format(
                    "HH:MM"
                  )}{" "}
                  -{" "}
                  {moment(new Date(`2000-01-01T${item.end_time}`)).format(
                    "HH:MM"
                  )}
                </Typography>
              </Box>
            </Grid>
          ))}
          {showRightArrow && (
            <IconButton
              style={{
                position: "absolute",
                right: 0,
                top: "40%",
                transform: "translateY(-50%)",
              }}
              onClick={() => handleScroll(100)}
            >
              <ArrowForward />
            </IconButton>
          )}
        </Grid>
      </Grid> */}
      {/* {isRecurring && (
        <FormikSelectScheduleField
          name="event_schedule_id"
          options={schedule}
        />
      )} */}
      <Typography
        color={colors.background.tertiary}
        fontFamily={"Comfortaa"}
        fontSize={"22px"}
        fontWeight={"700"}
        gutterBottom
      >
        Payment details
      </Typography>
      <Grid justifyContent={"space-between"} container spacing={0}>
        {/* <Grid item xs={12} sm={6}>
          <Typography
            color={colors.background.tertiary}
            fontFamily={"Comfortaa"}
            fontSize={"14px"}
            fontWeight={"500"}
            sx={{ my: 1 }}
          >
            <span>Price:</span> &nbsp;&nbsp;{" "}
            {price > 0 ? currency + price : "FREE"}
          </Typography>
        </Grid> */}
        {/* <Grid item xs={12} sm={6}>
          <Typography
            textAlign={{ sm: "end" }}
            fontFamily={"Campton"}
            style={{
              border: "none",
              background: "none",
              textDecoration: "underline",
              cursor: "pointer",
              color: colors.background.tertiary,
            }}
            onClick={() => setUseEmail(!useEmail)}
          >
            {useEmail ? "Use Phone" : "Use Email"}
          </Typography>
        </Grid> */}
        {/* <Grid item pr={{ md: 2 }} xs={12} md={6}>
          <FormikTextField
            fullWidth
            margin="dense"
            label={
              <Typography
                color={colors.background.tertiary}
                fontFamily={"Comfortaa"}
                fontSize={"14px"}
                fontWeight={"500"}
              >
                Full Name
              </Typography>
            }
            name="buyer_full_name"
            id="buyer_full_name"
            required
          />
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          {useEmail && (
            <FormikTextField
              fullWidth
              margin="dense"
              label={
                <Typography
                  color={colors.background.tertiary}
                  fontFamily={"Comfortaa"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  Email Address
                </Typography>
              }
              name="buyer_email"
              id="buyer_email"
              required
            />
          )}
          {!useEmail && (
            <FormikPhoneField
              fullWidth
              margin="dense"
              label={
                <Typography
                  color={colors.background.tertiary}
                  fontFamily={"Comfortaa"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  Phone Number
                </Typography>
              }
              name="buyer_phone"
              id="buyer_phone"
              required
            />
          )}
        </Grid> */}
        {/* <Grid item pr={{ md: 2 }} xs={12} md={6}>
          <FormikDateField
            fullWidth
            margin="dense"
            label={
              <Typography
                color={colors.background.tertiary}
                fontFamily={"Comfortaa"}
                fontSize={"14px"}
                fontWeight={"500"}
              >
                Date of Birth
              </Typography>
            }
            name="date_of_birth"
            id="date_of_birth"
            required
          />
        </Grid> */}

        {/* <Grid item xs={12} md={6}>
          <FormikTextField
            fullWidth
            margin="dense"
            type="number"
            label={
              <Typography
                color={colors.background.tertiary}
                fontFamily={"Comfortaa"}
                fontSize={"14px"}
                fontWeight={"500"}
              >
                Quantity
              </Typography>
            }
            name="quantity"
            id="quantity"
            required
          />
        </Grid> */}
        {!isFree && !bundle && (
          <Grid
            display={"flex"}
            alignItems={"center"}
            item
            pr={{ md: 2 }}
            xs={12}
            md={6}
          >
            <Grid>
              <FormikTextField
                // value={promoCode}
                onValueChange={(value) => {
                  setPromoCode(value);
                }}
                fullWidth
                margin="dense"
                label={
                  <Typography
                    color={colors.background.tertiary}
                    fontFamily={"Comfortaa"}
                    fontSize={"14px"}
                    fontWeight={"500"}
                  >
                    Promo Code
                  </Typography>
                }
                name="promocode"
                id="promocode"
              />
            </Grid>
            {typeof promoCodeDiscountRate === "number" &&
              !isNaN(promoCodeDiscountRate) && (
                <Typography
                  marginLeft={"5px"}
                  justifyContent={"center"}
                  color={colors.background.tertiary}
                  fontFamily={"Comfortaa"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  {promoCodeDiscountRate * 100}% Off
                </Typography>
              )}{" "}
          </Grid>
        )}

        {specialService && specialService?.length !== 0 && (
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="space-between">
              {/* <Grid item xs={12} lg={9}>
                <Typography
                  color={colors.background.tertiary}
                  fontFamily={"Comfortaa"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  Special Service Request
                  <span>
                    {" "}
                    <Tooltip
                      title={
                        specialService?.length == 0 ? (
                          <Typography
                            fontSize={"0.8rem"}
                            fontFamily={"Comfortaa"}
                          >
                            No Special Service Available{" "}
                          </Typography>
                        ) : (
                          <div>
                            {specialService?.map((special) => {
                              return (
                                <Typography
                                  fontSize={"0.8rem"}
                                  fontFamily={"Comfortaa"}
                                >
                                  {special}
                                </Typography>
                              );
                            })}
                          </div>
                        )
                      }
                      open={isTooltipOpen}
                      onClose={handleTooltipClose}
                    >
                      <InfoOutlined
                        onClick={handleInfoClick}
                        fontSize="1.3rem"
                      />
                    </Tooltip>{" "}
                  </span>
                </Typography>
              </Grid> */}

              <Grid item xs={12} lg={12}>
                {/* <FormikSwitchField name="specialService" /> */}
                <FormikSelectField
                  required
                  fullWidth
                  margin="dense"
                  id="specialService"
                  name="specialService"
                  labelId="specialService"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      Select Special Service
                    </Typography>
                  }
                  options={specialService}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* {!isFree && isPaymentMethodsVisible && (
          <FormikSelectWithImageField
            label={
              <Typography
                color={colors.background.tertiary}
                fontFamily={"Comfortaa"}
                fontSize={"14px"}
                fontWeight={"500"}
              >
                Payment Method
              </Typography>
            }
            onChange={(e) => {
              setSnapshot((prev) => {
                return {
                  ...prev,
                  selectedPaymentMethod: paymentMethods?.find(
                    (data) => data?.value === e
                  ),
                };
              });
            }}
            name="pMethod"
            options={paymentMethods}
            required
            paymentLoading={paymentLoading}
            eventLoading={eventLoading}
            setSnapshot={setSnapshot}
          />
        )} */}
      </Grid>
    </React.Fragment>
  );
}

export default PaymentForm;
