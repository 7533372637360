import { ArrowBack, ArrowForward } from "@mui/icons-material";
import React, { useRef, useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  //MenuItem,
  //Select,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  useTheme,
  //Radio,
  Switch,
  FormControlLabel,
  FormGroup,
  Checkbox,
  //Popper,
  createTheme,
} from "@mui/material";
import {
  DatePicker,
  /*MobileDatePicker, StaticDa,*/ StaticDatePicker /*StaticDatePickertePicker*/,
} from "@mui/x-date-pickers";
import { useField } from "formik";
import { MuiTelInput } from "mui-tel-input";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { colors } from "constants/themeConstants";
import { LanguageContext } from "hooks/useLanguage";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  StyledSelect,
  StyledTextField,
  StyledMenuItem,
} from "../SearchBar/Styles";
import {
  /*Circle, CircleOutlined,*/ RadioButtonCheckedTwoTone,
  /*RadioButtonUnchecked,*/ RadioButtonUncheckedTwoTone,
} from "@mui/icons-material";
import { ThemeProvider } from "@mui/styles";
import moment from "moment";
import { useGetEventScheduleQuery } from "features/events/eventsApiSlice";
import useGetDataPagination from "hooks/useGetDataPagination";
const PAGE_SIZE = 5;

export const FormikTextField = ({ typea, onValueChange, label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (event) => {
    field.onChange(event);
    onValueChange && onValueChange(event.target.value);
  };
  return (
    // <TextField
    //   sx={{
    //     my: 1,
    //     mr: 3,
    //     height: "4.5rem",
    //     width: "100%",
    //     "& .MuiOutlinedInput-root": {
    //       "& fieldset": {
    //         borderColor: colors.background.tertiary,
    //         backgroundColor: "none",
    //         outline: "none",
    //       },
    //       "&:active fieldset": {
    //         borderColor: colors.background.outline,
    //         backgroundColor: "none",
    //         outline: "none !important",
    //       },
    //       "&:hover fieldset": {
    //         borderColor: colors.background.secondary,
    //         backgroundColor: "none",
    //         outline: "none",
    //       },
    //       "&:focus fieldset": {
    //         borderColor: colors.background.secondary,
    //         backgroundColor: "none",
    //         outline: "none !important",
    //       },
    //       "& input": {
    //         color: "black",
    //       },
    //     },
    //   }}
    //   label={label}
    //   type={type}
    //   {...props}
    //   {...field}
    //   error={meta.touched && Boolean(meta.error)}
    //   helperText={meta.touched && meta.error}
    // />
    <TextField
      sx={{
        my: 1,
        mr: 3,
        height: "4.5rem",
        width: "100%",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.background.tertiary,
            backgroundColor: "none",
            outline: "none",
          },
          "&:active fieldset": {
            borderColor: colors.background.outline,
            backgroundColor: "none",
            outline: "none !important",
          },
          "&:hover fieldset": {
            borderColor: colors.background.secondary,
            backgroundColor: "none",
            outline: "none",
          },
          "&:focus fieldset": {
            borderColor: colors.background.secondary,
            backgroundColor: "none",
            outline: "none !important",
          },
          "& input": {
            color: "black",
          },
        },
      }}
      {...field}
      {...props}
      label={label}
      onChange={handleChange}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
    />
  );
};

export const FormikPasswordField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <StyledTextField
      label={label}
      {...props}
      {...field}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ border: "0pc solid transparent" }}
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? (
                <Visibility sx={{ colors: colors.background.tertiary }} />
              ) : (
                <VisibilityOff sx={{ colors: colors.background.tertiary }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export const FormikSelectField = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormControl
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      // sx={{
      //   my: 1,
      //   "& .MuiOutlinedInput-root": {
      //     "& fieldset": {
      //       borderColor: colors.background.outline, // Customize the original white border color
      //     },
      //     "&:active fieldset": {
      //       borderColor: colors.background.outline, // Customize the hover border color
      //     },
      //     "&:hover fieldset": {
      //       borderColor: colors.background.secondary, // Customize the hover border color
      //     },
      //     "&:focus fieldset": {
      //       borderColor: colors.background.secondary, // Customize the hover border color
      //     },
      //   },
      // }}
    >
      <InputLabel id={props.labelId}>{label}</InputLabel>
      <StyledSelect
        label={label}
        {...props}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      >
        {options.map((item) => (
          <StyledMenuItem value={item?.value || item}>
            {item?.label || item}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};
export const FormikSelectFieldForCategories = ({
  label,
  options,
  ...props
}) => {
  const { language } = useContext(LanguageContext);

  const [field, meta, helpers] = useField(props);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(options.length / PAGE_SIZE);
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = currentPage * PAGE_SIZE;
  const paginatedOptions = options.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <FormControl
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{ my: 1 }}
    >
      <InputLabel id={props.labelId}>{label}</InputLabel>
      <StyledSelect
        label={label}
        MenuProps={{
          sx: {
            "& .Mui-selected": {
              color: colors.background.tertiary,
            },
          },
        }}
        {...props}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: colors.background.outline,
                color: "black",
              },
            },
          },
        }}
      >
        {options.map((item) => (
          <StyledMenuItem
            sx={{
              fontFamily: "Campton",
              marginTop: "-0.5rem",
              marginBottom: "-0.5rem",
              "&:hover": {
                bgcolor: colors.background.secondary,
                color: "black",
              },
              // "&:active":{
              //   bgcolor: colors.background.secondary},
              "&:focus": {
                bgcolor: colors.background.outline,
                color: "black",
                fontWeight: 500,
              },
            }}
            value={item?.value || item.id}
          >
            {item?.label || item.name[language]}
          </StyledMenuItem>
        ))}
        {/* <StyledMenuItem
          sx={{
            fontFamily: "Campton",
          }}
          disabled
        >
          {currentPage} / {totalPages}
        </StyledMenuItem>
        {currentPage > 1 && (
          <StyledMenuItem
            sx={{
              fontFamily: "Campton",
            }}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </StyledMenuItem>
        )}
        {currentPage < totalPages && (
          <StyledMenuItem
            sx={{
              fontFamily: "Campton",
            }}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </StyledMenuItem>
        )} */}
        {/* {options.map((item) => (
          <MenuItem value={item?.id }>{item?.name }</MenuItem>
        ))} */}
      </StyledSelect>
    </FormControl>
  );
};

export const FormikSelectFieldForVendors = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { language } = useContext(LanguageContext);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(options?.length / PAGE_SIZE);
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = currentPage * PAGE_SIZE;
  const paginatedOptions = options?.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <FormControl
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{ my: 1 }}
    >
      <InputLabel id={props.labelId}>{label}</InputLabel>
      <StyledSelect
        label={label}
        {...props}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: colors.background.outline,
                color: "black",
              },
            },
          },
        }}
      >
        {/* {options?.map((item) => (
          <MenuItem value={item?.id }>{item?.company?.name[language] }</MenuItem>
        ))} */}
        {options?.map((item) => (
          <StyledMenuItem
            sx={{
              fontFamily: "Campton",
              marginTop: "-0.5rem",
              marginBottom: "-0.5rem",
              "&:hover": {
                bgcolor: colors.background.secondary,
                color: "black",
              },
              // "&:active":{
              //   bgcolor: colors.background.secondary},
              "&:focus": {
                bgcolor: colors.background.outline,
                color: "black",
                fontWeight: 500,
              },
            }}
            value={item?.value || item.id}
          >
            {item?.label || item?.company?.name[language]}
          </StyledMenuItem>
        ))}
        {/* <StyledMenuItem
          sx={{
            fontFamily: "Campton",
          }}
          disabled
        >
          {currentPage} / {totalPages}
        </StyledMenuItem> */}
        {/* {currentPage > 1 && (
          <StyledMenuItem
            sx={{
              fontFamily: "Campton",
            }}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </StyledMenuItem>
        )} */}
        {/* {currentPage < totalPages && (
          <StyledMenuItem
            sx={{
              fontFamily: "Campton",
            }}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </StyledMenuItem>
        )} */}
      </StyledSelect>
    </FormControl>
  );
};

export const FormikPhoneField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const isInternational = field.value?.startsWith("+251");
  const empty = field.value === "";

  return (
    <MuiTelInput
      sx={{
        my: 1,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.background.outline, // Customize the original white border color
          },
          "&:active fieldset": {
            borderColor: colors.background.outline, // Customize the hover border color
          },
          "&:hover fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          "&:focus fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          "& input": {
            color: "black", // Set the text color to black
          },
        },
      }}
      label={label}
      defaultCountry="ET"
      value={field.value}
      {...field}
      {...props}
      onChange={(value) => helpers.setValue(value)}
      error={meta.touched && Boolean(meta.error)}
      helperText={
        <span>
          {meta.touched && meta.error && <span>{meta.error} </span>}
          {!isInternational && !empty && (
            <span style={{ fontWeight: "bold" }}>
              <WhatsAppIcon fontSize="small" /> Ticket will be sent via your
              WhatsApp account.
            </span>
          )}
        </span>
      }
    />
  );
};
const lightTheme = createTheme({
  components: {
    MuiPicker: {
      styleOverrides: {
        datePickerRoot: {
          color: "black", // Set text color for light mode
          backgroundColor: "white", // Set background color for light mode
        },
      },
    },
  },
});
export const FormikDateField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider>
        <DatePicker
          label={label}
          {...props}
          {...field}
          onChange={(value) => helpers.setValue(value)}
          renderInput={(params) => (
            <StyledTextField
              sx={{
                my: 1,
                mr: 3,
                height: "4.5rem",
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: colors.background.tertiary,
                    backgroundColor: "none",
                    outline: "none", //
                    // Cu // Customize the original white border color
                  },

                  "&:active fieldset": {
                    borderColor: colors.background.outline,
                    backgroundColor: "none",
                    outline: "none !important", // Remove the default outline
                  },
                  "&:hover fieldset": {
                    borderColor: colors.background.secondary,
                    backgroundColor: "none",
                    outline: "none", //
                    // Cu // Customize the hover border color
                  },
                  "&:focus fieldset": {
                    borderColor: colors.background.secondary,
                    backgroundColor: "none",
                    outline: "none !important", // Remove the default outline
                  },
                  "& input": {
                    color: "black", // Set the text color to black
                  },
                },
              }}
              name={name}
              label={label}
              {...props}
              {...params}
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
            />
          )}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export const FormikDateTimeField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider
      sx={{ backgroundColor: "#f0f0f0" }}
      dateAdapter={AdapterMoment}
    >
      <Box sx={{ backgroundColor: "#f0f0f0" }}>
        <StaticDatePicker
          sx={{ my: 1, backgroundColor: "#f0f0f0" }}
          label={label}
          {...props}
          {...field}
          showToolbar={true}
          onChange={(value) => helpers.setValue(value)}
          // renderInput={(params) => (
          //   <StyledTextField

          //     name={name}
          //     label={label}
          //     {...props}
          //     {...params}
          //     error={meta.touched && Boolean(meta.error)}
          //     helperText={meta.touched && meta.error}
          //   />
          // )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export const FormikFileField = ({ label, accept, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState(null);
  useEffect(() => {
    if (field.value) {
      setValue(field.value.name);
    }
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Button
          variant="outlined"
          component="label"
          {...props}
          sx={{
            my: 1,
            border: `1px solid ${colors.background.outline}`,
            width: "40%",
            color: colors.background.tertiary,
            textTransform: "none",
            fontFamily: "Campton",
          }}
        >
          <AttachFileIcon
            sx={{
              width: "18px",
              height: "18px",
              color: colors.background.tertiary,
            }}
          />{" "}
          &nbsp;&nbsp;
          <Typography variant="p" color={colors.background.tertiary}>
            {label}
          </Typography>
          <input
            accept={accept}
            type="file"
            hidden
            onChange={(e) => {
              helpers.setValue(e.target.files[0]);
              setValue(e.target.files[0].name);
            }}
          />
        </Button>
        {value && <Typography sx={{ ml: 2 }}>{value}</Typography>}
      </Box>
      {Boolean(meta.error) && (
        <Typography sx={{ color: "error.main" }}>{meta.error}</Typography>
      )}
    </Box>
  );
};

export const FormikSelectWithImageField = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const theme = useTheme();
  useEffect(() => {
    if (!field.value && !props?.eventLoading && !props?.paymentLoading) {
      options?.length > 0 && helpers.setValue(options[0].value);
      options?.length > 0 &&
        props?.setSnapshot((prev) => {
          return {
            ...prev,
            selectedPaymentMethod: options[0],
          };
        });
    }
  }, [field.value, helpers, options]);
  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="body" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      <Grid container spacing={2}>
        {options.map((item, index) => (
          <Grid key={item.value} item xs={"auto"}>
            <Box
              onClick={() => {
                helpers.setValue(item.value);
                props?.onChange(item.value);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  border: `2px solid ${theme.palette.primary.main}`,
                },
                border: `${field.value === item.value ? "2px" : "1px"} solid ${
                  field.value === item.value
                    ? theme.palette.primary.main
                    : "#9e9d9d"
                }`,
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                px: 2,
                // p: 0.15,
              }}
            >
              <img
                src={item.label}
                alt={item.value}
                style={{
                  objectFit: "cover",
                  width: "auto",
                  height: "3rem",
                }}
              />
              {item?.discountRate && (
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "black",
                    backgroundColor: "#DEA449",
                    px: 2,
                    py: 1,
                    borderRadius: 5,
                    fontSize: "0.7rem",
                  }}
                >
                  {item?.discountRate * 100}% off
                </Typography>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      {meta.error && meta.touched ? (
        <Typography variant="p" color="error.main" sx={{ mt: 0.5 }}>
          {meta.error}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};
export const FormikSelectScheduleField = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const theme = useTheme();
  console.log(helpers); // Log helpers to see its content
  console.log("Selected value:", field.value); // Log the selected value
  localStorage.setItem("selectedScheduleId", field.value);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const contentRef = useRef(null);
  // useEffect(() => {
  //   if (options && options.length > 0 && !field.value) {
  //     const initialId = options[0].id;
  //     helpers.setValue(initialId);
  //     localStorage.setItem("selectedScheduleId", initialId);
  //     window.dispatchEvent(new Event("localStorageChanged"));
  //   }
  // }, [options]);
  useEffect(() => {
    const handleResizeAndScroll = () => {
      const container = contentRef.current;
      if (container) {
        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(
          container.scrollWidth > container.clientWidth + container.scrollLeft
        );
      }
    };

    const container = contentRef.current;
    if (container) {
      handleResizeAndScroll();
      container.addEventListener("scroll", handleResizeAndScroll);
      window.addEventListener("resize", handleResizeAndScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleResizeAndScroll);
        window.removeEventListener("resize", handleResizeAndScroll);
      }
    };
  }, []);

  const handleScroll = (scrollOffset) => {
    const container = contentRef.current;
    if (container) {
      container.scrollTo({
        left: container.scrollLeft + scrollOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <Grid style={{ overflowX: "auto" }} ref={contentRef}>
      <Grid spacing={2} style={{ display: "inline-flex" }}>
        {showLeftArrow && (
          <IconButton
            style={{
              position: "absolute",
              left: 0,
              top: "40%",
              transform: "translateY(-50%)",
            }}
            onClick={() => handleScroll(-100)}
          >
            <ArrowBack />
          </IconButton>
        )}
        {options?.map((item, index) => (
          <Grid key={item.id} item xs="auto">
            <Box
              onClick={() => {
                helpers.setValue(item.id);
              }}
              sx={{
                padding: "1.5rem 1rem 1.5rem 1rem",
                margin: "1rem",
                width: "10rem",
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#C5C6C6",
                cursor: "pointer",
                "&:hover": {
                  border: `1px solid ${theme.palette.primary.main}`,
                },
                border: `${field.value === item.id ? "2px" : "0px"} solid ${
                  theme.palette.primary.main
                }`, // Adjusted the border logic
                borderRadius: "10px",
              }}
            >
              <Typography
                marginRight={1}
                fontWeight={600}
                color={colors.background.tertiary}
                fontFamily="Campton"
              >
                {item.name}
              </Typography>
              <Grid display="flex">
                <Typography
                  marginRight={1}
                  fontWeight={600}
                  color={colors.background.tertiary}
                  fontFamily="Campton"
                >
                  {moment(new Date(item?.start_date)).format("MMM")}
                </Typography>
                <Typography
                  color={colors.background.tertiary}
                  fontFamily="Campton"
                >
                  {moment(new Date(item?.start_date)).format("DD,YYYY")}
                </Typography>
              </Grid>
              <Typography
                color={colors.background.tertiary}
                fontFamily="Campton"
              >
                {moment(new Date(`2000-01-01T${item.start_time}`)).format(
                  "HH:MM"
                )}{" "}
                -{" "}
                {moment(new Date(`2000-01-01T${item.end_time}`)).format(
                  "HH:MM"
                )}
              </Typography>
            </Box>
          </Grid>
        ))}
        {showRightArrow && (
          <IconButton
            style={{
              position: "absolute",
              right: 0,
              top: "40%",
              transform: "translateY(-50%)",
            }}
            onClick={() => handleScroll(100)}
          >
            <ArrowForward />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export const FormikMultiSelectField = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const theme = useTheme();
  const toggleValue = (value) => {
    const selectedValues = field.value.includes(value)
      ? field.value.filter((val) => val !== value)
      : [...field.value, value];
    helpers.setValue(selectedValues);
  };

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="body" sx={{ mb: 2 }}>
        {label}
      </Typography>
      <Grid container spacing={2}>
        {options?.map((item, index) => (
          <Grid key={item.id} item xs={6}>
            <Box
              onClick={() => toggleValue(item)}
              sx={{
                padding: "20px",
                cursor: "pointer",
                "&:hover": {
                  border: `2px solid ${colors.background.secondary}`,
                },
                border: `${
                  field?.value?.includes(item) ? "2px" : "1px"
                } solid ${colors.background.secondary}`,
                backgroundColor: field?.value?.includes(item)
                  ? colors.background.secondary
                  : "none",
                borderRadius: "20px",
              }}
            >
              <Checkbox
                checkedIcon={<RadioButtonCheckedTwoTone />}
                icon={<RadioButtonUncheckedTwoTone />}
                sx={{
                  color: colors.background.tertiary,
                  "&.Mui-checked": {
                    color: colors.background.tertiary,
                  },
                  borderRadius: "50%",
                }}
                checked={field.value.includes(item)}
                disableRipple
              />
              <Typography
                color={colors.background.tertiary}
                fontFamily={"Comfortaa"}
                display={"inline"}
                fontSize={"14px"}
                textAlign={"center"}
                fontWeight={"500"}
                // alt={item.value}
                // style={{
                //   objectFit: 'cover',
                //   width: 'auto',
                //   height: '3rem',
                // }}
              >
                {item.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {meta.error && meta.touched ? (
        <Typography variant="p" color="error.main" sx={{ mt: 0.5 }}>
          {meta.error}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};
export const FormikMultiSelectSmallField = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const theme = useTheme();

  const toggleValue = (value) => {
    const selectedValues = field.value.includes(value)
      ? field.value.filter((val) => val !== value)
      : [...field.value, value];
    helpers.setValue(selectedValues);
  };

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="body" sx={{ mb: 2 }}>
        {label}
      </Typography>
      <Grid container spacing={2}>
        {options.map((item, index) => (
          <Grid key={item.name} item xs={6}>
            <Box
              onClick={() => toggleValue(item.name)}
              sx={{
                padding: "10px",
                cursor: "pointer",
                "&:hover": {
                  border: `2px solid ${colors.background.secondary}`,
                },
                border: `${
                  field.value.includes(item.name) ? "2px" : "1px"
                } solid ${colors.background.secondary}`,
                backgroundColor: field.value.includes(item.name)
                  ? colors.background.secondary
                  : "none",
                borderRadius: "20px",
              }}
            >
              <Checkbox
                checkedIcon={<RadioButtonCheckedTwoTone />}
                icon={<RadioButtonUncheckedTwoTone />}
                sx={{
                  color: colors.background.tertiary,
                  "&.Mui-checked": {
                    color: colors.background.tertiary,
                  },
                  borderRadius: "50%",
                }}
                checked={field.value.includes(item.name)}
                disableRipple
              />
              <Typography
                color={colors.background.tertiary}
                fontFamily={"Comfortaa"}
                display={"inline"}
                fontSize={"14px"}
                textAlign={"center"}
                fontWeight={"500"}
                // alt={item.value}
                // style={{
                //   objectFit: 'cover',
                //   width: 'auto',
                //   height: '3rem',
                // }}
              >
                {item.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {meta.error && meta.touched ? (
        <Typography variant="p" color="error.main" sx={{ mt: 0.5 }}>
          {meta.error}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};
export const FormikSwitchField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            sx={{
              "& .MuiSwitch-thumb": {
                backgroundColor: colors.background.secondary, // Set the background color of the switch thumb
              },
              "& .MuiSwitch-track": {
                backgroundColor: `${colors.background.outline} !important`, // Set the background color of the switch track
              },
            }}
            checked={field.value}
            {...props}
            onChange={(e) => helpers.setValue(e.target.checked)}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};
export const FormikSelectFieldforSchedule = ({
  id,
  label,
  options,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const theme = useTheme();
  const {
    isLoading,
    pagination,
    setPagination,
    rowCount,
    data,
    setEventId,
    refetch,
  } = useGetDataPagination(id)(useGetEventScheduleQuery);
  useEffect(() => {
    setEventId(id);
  }, []);
  return (
    <Box sx={{ my: 1 }}>
      <Typography
        fontWeight={400}
        color={colors.background.tertiary}
        fontFamily={"Campton"}
        sx={{ mb: 0.5 }}
      >
        {"Dates"}
      </Typography>
      <Grid container spacing={2}>
        {data?.map((item, index) => (
          <Grid key={item.id} item xs={"auto"}>
            <Box
              sx={{
                padding: "1.5rem 1rem 1.5rem 1rem",
                margin: "1rem",
                width: "10rem",
                flexDirection: "column",
                display: "flex",
                alignItems: "center", // Align items vertically in the center
                justifyContent: "center",
                backgroundColor: colors.background.primary,
                borderRadius: "10px",
                cursor: "pointer",
                "&:hover": {
                  border: `2px solid ${colors.background.secondary}`,
                },
                border: `${field.value === item.id ? "2px" : "1px"} solid ${
                  field.value === item.id
                    ? colors.background.secondary
                    : colors.background.outline
                }`,
                // p: 0.15,
              }}
              onClick={() => {
                helpers.setValue(item.id);
              }}
            >
              <Typography
                fontWeight={600}
                color={colors.background.tertiary}
                fontFamily={"Campton"}
              >
                {moment(new Date(item?.start_date)).format("MMM")}
              </Typography>
              <Typography
                color={colors.background.tertiary}
                fontFamily={"Campton"}
              >
                {moment(new Date(item?.start_date)).format("DD,YYYY")}
              </Typography>
              <Typography
                color={colors.background.tertiary}
                fontFamily={"Campton"}
              >
                {moment(new Date(`2000-01-01T${item.start_time}`)).format(
                  "HH:MM"
                ) +
                  " - " +
                  moment(new Date(`2000-01-01T${item.end_time}`)).format(
                    "HH:MM"
                  )}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {meta.error && meta.touched ? (
        <Typography variant="p" color="error.main" sx={{ mt: 0.5 }}>
          {meta.error}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};
