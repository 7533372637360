import {
  useTheme,
  Box,
  Typography,
  //LinearProgress,
  Grid,
  Tooltip,
  tooltipClasses,
  //Button,
} from "@mui/material";
import { apiUrl /*portalLoginUrl, vendorURl*/ } from "constants/api";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
//import StorefrontIcon from "@mui/icons-material/Storefront";
//import img from "../../../assets/images/EventDetail.png";
import {
  StyledButton,
  StyledCategoryChip,
  StyledDateTextBody,
  StyledDateTextTitle,
  StyledEventSquareCardWrapper,
  //StyledInfo,
  //StyledInfoMore,
  StyledLinearProgress,
  StyledSmallText,
  //StyledSmallTextBlack,
  StyledSquareCardImg,
  StyledTitle,
} from "./Styles";
import /*FeaturedBody,
  FeaturedSubTitle,
  FeaturedTitle,*/
"../FeaturedEventCard/Styles";
import { colors } from "../../../constants/themeConstants";
import moment from "moment";
//import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoCart /*IoPricetagOutline*/ } from "react-icons/io5";
import { IoStorefront } from "react-icons/io5";
//import { HiMusicNote } from "react-icons/hi";
//import { FaHiking } from "react-icons/fa";
//import { FaRegBuilding } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
import { LanguageContext } from "hooks/useLanguage";
import { localization } from "constants/localalization";
import CheckoutModal from "components/Checkout/CheckoutModal";
import CheckoutWebsiteModal from "components/CheckoutWebsite/CheckoutModal";
import Marquee from "react-fast-marquee";
import ReactGA from "react-ga4";
const { styled } = require("@mui/system");

const SquareEventCard = ({ event }) => {
  // console.log(event)
  const { language } = useContext(LanguageContext);
  const boxRef = useRef(null);
  const info = event?.detail ? event?.detail[language] : "";
  // const [isOverflow, setIsOverflow] = useState(false);
  const content =
    "sjfnsfjks fsdjnf hfsiejf ksnf kdsn fjdksn fcsjebfc sj fcsejd fcsd cfsd cfsdh cf hjbm hjb hm hm b h hj h hj h hnvbhvghb hgvchb hgnb vgtfgvh bgs ";
  useEffect(() => {
    const boxElement = boxRef.current;
    setIsOverflow(boxElement.scrollHeight > boxElement.clientHeight);
  }, [info]);
  const [isTruncated, setIsTruncated] = useState(false);
  const [truncatedValue, setTruncatedValue] = useState();
  console.log(event?.tickets);

  console.log(event?.tickets.sales_channel === "DOOR_ONLY");

  useEffect(() => {
    if (info?.length > 90) {
      setIsTruncated(true);
      setTruncatedValue(info.substring(0, 90));
    }
  }, [language]);
  const theme = useTheme();
  const navigate = useNavigate();
  const normalise = (value) => ((value - 0) * 100) / (300 - 0);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      background: "none",

      fontSize: theme.typography.pxToRem(12),
    },
  }));
  const ref = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [modalData, setModalData] = useState({
    open: false,
    id: 0,
    price: 0,
    tickets: [],
    name: "",
  });
  const outerRef = useRef(null);
  const innerRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);
  // console.log(event)
  useEffect(() => {
    if (outerRef.current && innerRef.current) {
      const outerHeight = outerRef.current.getBoundingClientRect().height;
      const innerHeight = innerRef.current.getBoundingClientRect().height;
      const isOverflowing = innerHeight > outerHeight;

      setIsOverflow(isOverflowing);
    }
  }, [content]);

  useEffect(() => {
    if (ref.current) {
      const { clientHeight, scrollHeight } = ref.current;

      if (clientHeight < scrollHeight) {
        setHasOverflow(true);
      }
    }
  }, []);
  const handleMoreButtonClick = () => {
    navigate(`/events/detail?event_id=${event.id}`);
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      // Find the last space within the maxLength
      const lastSpaceIndex = text.lastIndexOf(" ", maxLength);
      // If there's a space within the maxLength, truncate at that space
      if (lastSpaceIndex !== -1) {
        const truncatedText = text.slice(0, lastSpaceIndex) + "... ";
        return (
          <React.Fragment>
            {truncatedText}
            <button
              onClick={handleMoreButtonClick}
              style={{
                color: colors.background.secondary,

                display: "inline",
              }}
            >
              More
            </button>{" "}
          </React.Fragment>
        );
      } else {
        // If no space found, truncate at maxLength
        return (
          <React.Fragment>
            {text.slice(0, maxLength)}
            <button onClick={handleMoreButtonClick}>More</button>
          </React.Fragment>
        );
      }
    }
    return text;
  };

  return (
    <StyledEventSquareCardWrapper
      my={2}
      mx={1}
      // width={{ xs: "320px", md: "500px" }}
      // height={{ xs: "350px" }}
    >
      <Grid
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        spacing={2}
        container
      >
        <Grid xs={12} md={12} item>
          <Box
            sx={{
              // width: "400px",
              // maxHeight: "300px",
              // overflow: "hidden",
              position: "relative",
            }}
          >
            <StyledSquareCardImg
              className="max-h-[220px] sm:max-h-[220px] md:max-h-[220px] lg:max-h-[200px] xl:max-h-[200px] 2xl:max-h-[200px] 3xl:max-h-[200px]"
              style={{ aspectRatio: "16/9" }}
              src={`${apiUrl}${event?.image?.image_medium_path}`}
              alt="Event Banner"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });

                navigate(`/events/detail?event_id=${event.id}`);
                ReactGA.event({
                  category: "Events",
                  action: `Event viewed`,
                  label: `Event Name: ${event.name[language]}`,
                });
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 5,
                left: 5,
                zIndex: 100,
                backgroundColor: "rgba(107, 106, 106,0.7)",
                px: 2,
                py: 1,
                borderRadius: 10,
              }}
            >
              <Typography
                sx={{
                  color: "#f0ebeb",
                  fontWeight: 900,
                  fontSize: { xs: "0.6rem", md: "0.8rem" },
                }}
              >
                {event?.status}
              </Typography>
            </Box>
            {event?.is_free && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: 5,
                  left: 5,
                  zIndex: 100,
                  backgroundColor: "#DEA449",
                  px: 3,
                  py: 0.5,
                  borderRadius: 10,
                }}
              >
                <Typography
                  sx={{
                    color: "#f0ebeb",
                    fontWeight: 900,
                    fontSize: { xs: "0.6rem", md: "0.8rem" },
                  }}
                >
                  Free
                </Typography>
              </Box>
            )}
            <Box sx={{ position: "absolute", top: 5, right: 10 }}>
              {event.allow_vendors && (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <div
                        style={{
                          width: "132px",
                          height: "auto",
                          padding: "7px",
                          border: "1px solid #dadde9",
                          objectFit: "cover",
                          borderRadius: 10,
                          backgroundColor: colors.background.pureWhite,
                        }}
                      >
                        <Grid container>
                          <Grid item xs={2}>
                            <IoStorefront
                              style={{
                                width: 10,
                                height: 10,
                                color: colors.background.tertiary,
                              }}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography
                              sx={{ color: colors.background.tertiary }}
                              marginTop={"5%"}
                              fontFamily={"Campton"}
                              fontSize={7}
                            >
                              {"Open for Vendors"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </React.Fragment>
                  }
                >
                  <div
                    className="mx-[auto] my-[auto] p-1.5 rounded-xl"
                    style={{ backgroundColor: colors.background.tertiary }}
                  >
                    <IoStorefront
                      elevation={10}
                      style={{ color: colors.background.secondary }}
                      size={25}
                    />
                  </div>
                </HtmlTooltip>
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: 2,
                left: "10px",
                marginRight: "10px", // flexDirection: "row",
                // justifyContent: "center"
              }}
            >
              {/* <StyledLinearProgress
                sx={{
                  borderRadius: 5,
                  boxShadow: 25,
                }}
                className="w-full"
                variant="determinate"
                value={
                  event?.available_ticket_rate
                    ? 100 - event?.available_ticket_rate * 100
                    : 0
                }
              /> */}
            </Box>
          </Box>
          <Grid height={"fit-content"} container mt={1} spacing={1}>
            <Grid
              style={{
                width: "fit-content",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              xs={12}
              lg={4}
              xl={4}
              dxl={4}
              ddxl={4}
              alignSelf={"center"}
            >
              <StyledDateTextTitle
                style={{ color: colors.background.secondary, fontSize: "1rem" }}
              >
                {localization.Labels.date[language]}{" "}
              </StyledDateTextTitle>{" "}
              <StyledDateTextBody
                style={{ color: colors.background.tertiary, fontSize: "1rem" }}
              >
                {event?.is_recurring
                  ? "Multiple Dates"
                  : moment(event.start_date).format("MMM DD, YY")}
              </StyledDateTextBody>{" "}
              <br />
              <div className="flex flex-row gap-2">
                <StyledDateTextTitle
                  style={{
                    color: colors.background.secondary,
                    fontSize: "1rem",
                  }}
                >
                  {localization.Labels.place[language]}{" "}
                </StyledDateTextTitle>{" "}
                <StyledDateTextBody
                  style={{
                    color: colors.background.tertiary,
                    fontSize: "1rem",
                  }}
                >
                  <Marquee speed={30}>
                    {event?.location?.name || event?.location_name}
                  </Marquee>
                </StyledDateTextBody>
              </div>
            </Grid>
            {event?.status != "PAST" && (
              <>
                <Grid xs={7} lg={4} item alignSelf={"center"}>
                  <Box display="flex" justifyContent="flex-end">
                    <StyledButton
                      sx={{
                        width: "97%",
                        border: `dashed 1px ${colors.background.secondary}`,
                      }}
                      className="group"
                      onClick={() => {
                        // window.open(vendorURl, "_self");
                        navigate("/vendors/register");
                      }}
                      startIcon={
                        <IoIosNotifications
                          className={`group-hover:bg-[${colors.background.secondary}] group-hover:border-white border-[1px] border-[${colors.background.outline}] group-hover:text-white text-slate-800`}
                          style={{
                            padding: 2,
                            width: "20px",
                            height: "20px",
                            borderRadius: "50px",
                            // border: `1px solid ${colors.background.outline}`,
                            // background: colors.background.pureWhite,
                          }}
                        />
                      }
                      variant="text"
                    >
                      <StyledDateTextBody
                        style={{
                          color: colors.background.white,
                        }}
                        className="sm:text-[15px] md:text-[13px] lg:text-[11px] group-hover:text-white hover:font-medium"
                      >
                        {localization.Labels.get[language]} <br />{" "}
                        {localization.Labels.notification[language]}
                      </StyledDateTextBody>{" "}
                    </StyledButton>
                  </Box>
                </Grid>

                <Grid xs={5} lg={3.5} item alignSelf={"center"}>
                  <Box display={"flex"} justifyContent="flex-end">
                    <StyledButton
                      className="group"
                      onClick={() => {
                        if (
                          event.tickets.every(
                            (ticket) => ticket?.sales_channel === "DOOR_ONLY"
                          ) ||
                          event?.is_free
                        ) {
                          // Redirect to event detail page if no tickets available or sales channel is DOOR_ONLY
                          navigate(`/events/detail?event_id=${event.id}`);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        } else {
                          // Open modal
                          setModalData((prev) => ({
                            ...prev,
                            open: true,
                            id: 0,
                            isFree: event?.is_free,
                            eventId: event?.id,
                            desc: "item.desc",
                            name: event.name[language],
                            price: -1,
                            tickets: event?.tickets,
                            isRecurring: event?.is_recurring,
                          }));
                        }
                      }}
                      // onClick={() =>

                      //  {

                      //   navigate(`/events/detail?event_id=${event.id}`);
                      //   }
                      // }
                      sx={{
                        width: "97%",
                        border: "none",
                        border: `dashed 1px ${colors.background.secondary}`,
                      }}
                      startIcon={
                        <IoCart
                          className={`group-hover:bg-[${colors.background.secondary}] group-hover:border-white border-[1px] border-[${colors.background.outline}] group-hover:text-white text-slate-800`}
                          style={{
                            // marginLeft:9,
                            // color: colors.background.tertiary,
                            padding: 3,
                            width: "20px",
                            height: "20px",
                            borderRadius: "50px",
                            // border: `1px solid ${colors.background.outline}`,
                            // background: colors.background.pureWhite,
                          }}
                        />
                      }
                      variant="outlined"
                    >
                      <StyledDateTextBody
                        style={
                          {
                            //   color: colors.background.pureWhite,
                          }
                        }
                        className="sm:text-[15px] md:text-[13px] lg:text-[11px] group-hover:text-white hover:font-medium"
                      >
                        {event?.is_free
                          ? event?.tickets?.length === 0
                            ? localization.Labels.open[language]
                            : localization.Labels.free[language]
                          : event.tickets.every(
                              (ticket) => ticket?.sales_channel === "DOOR_ONLY"
                            )
                          ? localization.Labels.doorticket[language]
                          : localization.Labels.buy[language]}
                        <br />{" "}
                        {event?.is_free
                          ? event?.tickets?.length === 0
                            ? localization.Labels.door[language]
                            : localization.Labels.ticket[language]
                          : event.tickets.every(
                              (ticket) => ticket?.sales_channel === "DOOR_ONLY"
                            )
                          ? localization.Labels.ticket[language]
                          : localization.Labels.ticket[language]}
                      </StyledDateTextBody>{" "}
                    </StyledButton>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          xs={12}
          md={12}
          ref={boxRef}
          mt={1}
          item
          sx={{
            width: "fit-content",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
            height: {
              xs: "10rem",
              sm: "10em",
              md: "11rem",
              // lg: 1150,
              // xl: 1350,
              // dxl: 1441,
              // ddxl: 1650,
              // md: "8rem",
              lg: "9rem",
              xl: "10.5rem",
              ddxl: "14rem",
            },
          }}
        >
          <StyledTitle
            // ref={ref}
            style={{
              width: "100%",
              fontSize: "1.05rem",
              fontFamily: "Campton",
              fontWeight: 500,
              textAlign: "start",
              display: "block",
              color: colors.background.tertiary,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
            }}
          >
            {event.name[language]}
          </StyledTitle>
          <Grid
            style={{
              width: "100%",

              overflow: "hidden",
            }}
            spacing={1}
            marginBottom={1}
            container
          >
            {event?.categories?.map((cat) => (
              <Grid item key={cat.id} display="flex">
                {" "}
                <StyledCategoryChip
                  onClick={() => {
                    navigate(
                      `/events/?category_id=${cat.id}&name=${cat.name[language]}`
                    );
                  }}
                  // style={{
                  //   color: colors.background.almostBlack,
                  //   backgroundColor: colors.background.secondary,
                  //   "&:hover": {
                  //     backgroundColor: colors.background.primary,
                  //     // filter: `drop-shadow(5px 5px 10px rgba(222, 164, 73, 0.5))`,
                  //   },
                  // }}
                  label={
                    <StyledSmallText color={colors.background.almostBlack}>
                      {cat.name[language]}
                    </StyledSmallText>
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Grid
            ref={boxRef}
            className="box"
            style={{ fontFamily: "Comfortaa", fontSize: "0.7rem" }}
            sx={{ height: { xs: "3rem", md: "auto" }, overflow: "hidden" }}
          >
            {/* {info} */}
            {truncateText(info, 180)}
            {isOverflow && (
              <button
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });

                  navigate(`/events/detail?event_id=${event.id}`);
                }}
              >
                More Info
              </button>
            )}
          </Grid>
          {/* <StyledInfo
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });

              navigate(`/events/detail?event_id=${event.id}`);
            }}
            style={{
              // width: "100%",
              // fontSize: "1.05rem",
              // fontFamily: "Campton",
              // fontWeight: 500,
              textAlign: "start",
              display: "block",
              cursor: "pointer",
              lineHeight: "0.8rem",
              color: colors.background.tertiary,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
            
              // WebkitLineClamp: 5,
              // overflow: "hidden",
              textOverflow:'clip'
            }}
          
          >{info}
           
            {isOverflow &&  <StyledInfoMore
                style={{
                  color: colors.background.secondary,
                  display: "inline",
                }}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate(`/events/detail?event_id=${event.id}`);
                }}
              >
                {" More info..."}
              </StyledInfoMore>}
          </StyledInfo> */}
        </Grid>
      </Grid>
      {/* <div
      className="box"
      style={{ height: `30px`, overflow: "hidden" }}
      ref={outerRef}
    >
      <div ref={innerRef}>{content}</div>
      {isOverflow && (
        <button onClick={() => alert("More info clicked!")}>More Info</button>
      )}
    </div> */}
      {/* <div  ref={boxRef} className="box" style={{ height: "70px", overflow: "hidden" }}>
      {content}
      {isOverflow && <button onClick={() => alert("More info clicked!")}>More Info</button>}
    </div> */}
      {process.env.REACT_APP_WEBSITE === "M_PESA" ? (
        <CheckoutModal modalData={modalData} setModalData={setModalData} />
      ) : (
        <CheckoutWebsiteModal
          modalData={modalData}
          setModalData={setModalData}
        />
      )}
    </StyledEventSquareCardWrapper>
  );
};

export default SquareEventCard;
