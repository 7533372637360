import { apiSlice } from "app/api/apiSlice";
import { format } from "date-fns";

function getLocation(coordinates) {
  return `${coordinates.lat},${coordinates.lon}`;
}
export const getDate = (date) => {
  try {
    return format(date, "PP");
  } catch (error) {
    return "error";
  }
};

export const getTime = (date) => {
  try {
    return format(date, "pp");
  } catch (error) {
    return "error";
  }
};

export const eventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters, literalFilter } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/portal/public/events/?${literalFilter}&page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const events = data.map((item) => ({
          ...item,
          name: item.name,
          location: item.is_virtual ? null : item.location.coordinates,
          event_categories: item.categories.map((item) => item.name).join(","),
          event_type: item.event_type,
          id: item.id,
          originalEvent: item,
          isCanceled: item.is_canceled,
          status: item.status,
          attendees_count: item.attendees_count,
          location_name: item.is_virtual
            ? item.virtual_meeting_url
            : item.location.name,
          has_invitation: item.has_invitation,
          ticket_scan_method: item.ticket_scan_method,
          formatted_start_date: item.start_date
            ? getDate(new Date(item.start_date))
            : "-",
          formatted_start_time: item.start_date
            ? getTime(new Date(item.start_date))
            : "-",
          formatted_end_date: item.end_date
            ? getDate(new Date(item.end_date))
            : "-",
          formatted_end_time: item.end_date
            ? getTime(new Date(item.end_date))
            : "-",
        }));
        return {
          page_metadata,
          data: events,
        };
      },
      providesTags: ["Event"],
    }),

    getEvent: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/event/detail/?event_id=${id}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Events Details");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getEventsbyCategory: builder.query({
      query: (query) => {
        const { pagination, id } = query;
        const { pageIndex, pageSize } = pagination;

        return {
          url: `portal/public/events/?category_id=${id}&page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Catagories");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getNewEvents: builder.query({
      query: (query) => {
        const { pagination } = query;
        const { pageIndex, pageSize } = pagination;
        return {
          url: `/portal/public/events/new/?page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        if (!response.success) {
          throw new Error("Error Loading New Events");
        }
        return {
          page_metadata,
          data: data,
        };
      },
      onError: (error) => {
        console.error("error happened here" + error);
      },
      providesTags: ["Event"],
    }),
    getLiveEvents: builder.query({
      query: (query) => {
        const { pagination } = query;
        const { pageIndex, pageSize } = pagination;
        return {
          url: `/portal/public/events/?page=${pageIndex}&page_size=${pageSize}&status=ONGOING`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        if (!response.success) {
          throw new Error("Error Loading New Events");
        }
        return {
          page_metadata,
          data: data,
        };
      },
      onError: (error) => {
        console.error("error happened here" + error);
      },
      providesTags: ["Event"],
    }),
    getNewLength: builder.query({
      query: (query) => {
        return {
          url: `/portal/public/events/new/?page=0&page_size=12`,
        };
      },

      providesTags: ["Event"],
    }),
    getLiveLength: builder.query({
      query: (query) => {
        return {
          url: `/portal/public/events/?status=ONGOING&page=0&page_size=12`,
        };
      },

      providesTags: ["Event"],
    }),
    getSimilarEvents: builder.query({
      query: (query) => {
        const { pagination, id } = query;
        const { pageIndex, pageSize } = pagination;

        return {
          url: `/portal/public/events/similar/?event_id=${id}&page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Similar Events");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getEventSchedule: builder.query({
      query: (query) => {
        const { pagination, id } = query;
        const { pageIndex, pageSize } = pagination;

        return {
          url: `/portal/public/event/schedule/?event_id=${id}&page=${pageIndex}&page_size=${pageSize}&order_by=name&order_by_clause=ASC`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Schedule for Events");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),

    getUpcomingEvents: builder.query({
      query: (query) => {
         const { pagination, id } = query;
      const { pageIndex, pageSize } = pagination;
        return {
          url: `/portal/public/home/upcoming_events/?order_by=start_date&order_by_clause=ASC&page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {

        if (!response.success) {
          throw new Error("Error Loading Upcoming Events");
        }
        return response.data;
      },

      providesTags: ["Event"],
    }),
    getHotEvents: builder.query({
      query: (query) => {
        const { pagination, id } = query;
        const { pageIndex, pageSize } = pagination;
        return {
          url: `/portal/public/home/hot_events/?page=${pageIndex}&page_size=${pageSize}&order_by=start_date&order_by_clause=ASC`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Hot Events");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getCities: builder.query({
      query: () => {
        return {
          url: `/portal/public/location/cities/`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Cities");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getTrendingEvents: builder.query({
      query: (query) => {
        const { pagination, id } = query;
        const { pageIndex, pageSize } = pagination;
        return {
          url: `/portal/public/home/trending_events/?page=${pageIndex}&page_size=${pageSize}&order_by=start_date&order_by_clause=ASC`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Trendng Events");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getEventVideos: builder.query({
      query: () => {
        return {
          url: `/portal/public/videos/?page=0&page_size=10`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      // providesTags: ["Event"],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventQuery,
  useGetUpcomingEventsQuery,
  useGetHotEventsQuery,
  useGetTrendingEventsQuery,
  useGetSimilarEventsQuery,
  useGetCitiesQuery,
  useGetNewEventsQuery,
  useGetLiveEventsQuery,
  useGetEventsbyCategoryQuery,
  useGetLiveLengthQuery,
  useGetNewLengthQuery,
  useGetEventScheduleQuery,
  useGetEventVideosQuery,
} = eventsApiSlice;
