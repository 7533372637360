import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
//import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
//import LogoDevIcon from "@mui/icons-material/LogoDev";
import { colors } from "constants/themeConstants";
import { useLocation } from "react-router-dom";
import {
  /*AccountBoxOutlined,
  AccountCircleOutlined,
  AccountTreeOutlined,
  LocationCityOutlined,*/
  LocationOn,
  Search,
  //VerifiedUserOutlined,
} from "@mui/icons-material";
import { BiUser } from "react-icons/bi";
//import {BiUserCircle } from "react-icons/bi";

//import { FiLogIn } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
//import { Navigate, Route, Routes } from "react-router-dom";

//import { routes } from "routes";
//import ProtectedRoute from "ProtectedRoute";
//import img from "../../assets/images/logo1.png";
import { Badge } from "@mui/material";
//import {withStyles } from "@mui/material";

import { apiUrl, portalLoginUrl } from "constants/api";
import { useGetSettingsQuery } from "features/settings";
import {
  useGetLiveLengthQuery,
  useGetNewLengthQuery,
} from "features/events/eventsApiSlice";
import { localization } from "../../constants/localalization";
import { MdCurrencyExchange, MdLanguage } from "react-icons/md";
import { LanguageContext } from "hooks/useLanguage";
import { StyledBox, StyledIconButton } from "./Styles";
import { CurrencyContext } from "hooks/useCurrency";
const settings = [
  { name: "Amharic", code: "text_am" },
  { name: "English", code: "text_en" },
  { name: "KiSwahili", code: "text_sw" },
  { name: "French", code: "text_fr" },
  { name: "Arabic", code: "text_ar" },
];
const currencySetting = [
  { name: "ETB", code: "ETB" },
  { name: "USD", code: "USD" },
];
function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElCur, setAnchorElCur] = React.useState(null);
  const location = useLocation();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenCurMenu = (event) => {
    setAnchorElCur(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseCurMenu = () => {
    setAnchorElCur(null);
  };
  const { language, changeLanguage } = React.useContext(LanguageContext);
  const { currency, changeCurrency } = React.useContext(CurrencyContext);
  const handleLanguageChange = (language) => {
    // localStorage.setItem("preferredLanguage", language);
    changeLanguage(language);
    setAnchorElUser(null);
  };
  const handlecurrencyChange = (language) => {
    // localStorage.setItem("preferredLanguage", language);
    changeCurrency(language);
    setAnchorElCur(null);
  };
  const { data } = useGetNewLengthQuery();
  const newLength = data?.data?.page_metadata?.length;
  const { data: live } = useGetLiveLengthQuery();
  const liveLength = live?.data?.page_metadata?.length;
  const { data: event, isLoading } = useGetSettingsQuery();
  const navigate = useNavigate();
  const pages = [
    // {name: "Organizer", badge:false},
    {
      link: "New",
      name: localization.Menu.new[language],
      badge: true,
      number: newLength,
      id: 1,
      url: "/New",
    },

    // {
    //   link: "Services",
    //   name: localization.Menu.services[language],
    //   badge: false,
    //   id: 3,
    // },
  ];
  const pages1 = [
    // {name: "Organizer", badge:false},

    {
      link: "Live",
      name: localization.Menu.live[language],
      badge: true,
      number: liveLength,
      id: 2,
      url: "/Live",
    },
    // {
    //   link: "Services",
    //   name: localization.Menu.services[language],
    //   badge: false,
    //   id: 3,
    // },
  ];
  const pages2 = [
    // {name: "Organizer", badge:false},

    // {
    //   link: "Services",
    //   name: localization.Menu.services[language],
    //   badge: false,
    //   id: 3,
    // },
    {
      link: "Subscription Fee",
      name: localization.Menu.subFee[language],
      badge: false,
      id: 4,
      url: "/Subscription%20Fee",
    },
    {
      link: "Ticketing App",
      name: "OwlEvents App",

      // name: localization.Menu.ticketing[language],
      badge: false,
      id: 5,
      url: "/Ticketing%20App",
    },
    {
      link: "Help",
      name: localization.Menu.help[language],
      badge: false,
      id: 6,
      url: "/Help",
    },
  ];

  return (
    // <>
    <AppBar
      elevation={0}
      style={{
        paddingTop: "5px",
        border: `solid 3px ${colors.background.primary} `,
        height: "80px",
        background: colors.background.pureWhite,
      }}
      position="sticky"
    >
      <Container maxWidth="3xl">
        <Toolbar disableGutters>
          <StyledBox>
            <img
              // className="ml-5"
              onClick={() => navigate("/")}
              width={"50px"}
              src={`${apiUrl}${event?.logo?.image_small_path}`}
              alt="logo"
              // src={img}
              // style={{ marginLeft: "40px", marginBottom: 0 }}
            />
          </StyledBox>
          <Box
            sx={{
              flexGrow: 1,

              display: { xs: "flex", lg: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{
                color: colors.background.tertiary,
              }}
              // color='black'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              // anchorOrigin={{
              //   vertical: "bottom",
              //   horizontal: "right",
              // }}
              // keepMounted
              // transformOrigin={{
              //   vertical: "top",
              //   horizontal: "right",
              // }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
                justifyContent: "center",
                alignItems: "center",
                justifyItems: "center",
                alignContent: "center",
              }}
            >
              {newLength > 0 &&
                pages.map((page) => (
                  <MenuItem
                    key={page.id}
                    onClick={() => navigate(`/${page.link}`)}
                    sx={{
                      bgcolor: `${colors.background.pureWhite}`,
                      marginTop: "-0.5rem",
                      marginBottom: "-0.5rem",
                      backgroundColor:
                        location.pathname === `${page.url}`
                          ? "#f5dbb3"
                          : "white",
                      "&:hover": {
                        bgcolor: colors.background.secondary,
                      },
                      "&:active": {
                        bgcolor: colors.background.secondary,
                      },
                      // "&:focus":{
                      //   bgcolor: colors.background.secondary},
                    }}
                  >
                    {page.badge && page.number > 0 && (
                      <Badge
                        badgeContent={page.number}
                        max={10}
                        color={"primary"}
                      >
                        <Typography
                          fontFamily={"Campton"}
                          textTransform={"none"}
                          color={colors.background.tertiary}
                          textAlign="center"
                        >
                          {page.name}
                        </Typography>
                      </Badge>
                    )}
                  </MenuItem>
                ))}
              {liveLength > 0 &&
                pages1.map((page) => (
                  <MenuItem
                    key={page.id}
                    onClick={() => navigate(`/${page.link}`)}
                    sx={{
                      bgcolor: `${colors.background.pureWhite}`,
                      marginTop: "-0.5rem",
                      marginBottom: "-0.5rem",
                      backgroundColor:
                        location.pathname === `${page.url}`
                          ? "#f5dbb3"
                          : "white",
                      "&:hover": {
                        bgcolor: colors.background.secondary,
                      },
                      "&:active": {
                        bgcolor: colors.background.secondary,
                      },
                      // "&:focus":{
                      //   bgcolor: colors.background.secondary},
                    }}
                  >
                    {page.badge && page.number > 0 && (
                      <Badge
                        badgeContent={page.number}
                        max={10}
                        color={"primary"}
                      >
                        <Typography
                          fontFamily={"Campton"}
                          textTransform={"none"}
                          color={colors.background.tertiary}
                          textAlign="center"
                        >
                          {page.name}
                        </Typography>
                      </Badge>
                    )}
                  </MenuItem>
                ))}

              {pages2.map((page) => (
                <MenuItem
                  key={page.id}
                  onClick={() => navigate(`/${page.link}`)}
                  sx={{
                    bgcolor: `${colors.background.pureWhite}`,
                    marginTop: "-0.5rem",
                    marginBottom: "-0.5rem",
                    backgroundColor:
                      location.pathname === `${page.url}` ? "#f5dbb3" : "white",
                    "&:hover": {
                      bgcolor: colors.background.secondary,
                    },
                    "&:active": {
                      bgcolor: colors.background.secondary,
                    },
                    // "&:focus":{
                    //   bgcolor: colors.background.secondary},
                  }}
                >
                  <Badge badgeContent={page.number} max={10} color={"primary"}>
                    <Typography
                      fontFamily={"Campton"}
                      textTransform={"none"}
                      color={colors.background.tertiary}
                      textAlign="center"
                    >
                      {page.name}
                    </Typography>
                  </Badge>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex" },
              justifyContent: "center",
            }}
          >
            {pages.map((page) => (
              <>
                {page.badge && page.number !== 0 && (
                  <Button
                    key={page}
                    // color={colors.background.white}

                    onClick={() => navigate(`/${page.link}`)}
                    sx={{
                      mx: 2,
                      color: colors.background.tertiary,
                      display: "block",
                      textTransform: "none",
                      fontFamily: "Campton",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    <Badge badgeContent={page.number} max={10} color="primary">
                      <Typography
                        sx={{
                          borderBottomWidth:
                            location.pathname === `${page.url}` ? 1 : 0,
                          borderBottomColor: "#DEA449",
                        }}
                      >
                        {page.name}
                      </Typography>
                    </Badge>
                  </Button>
                )}
              </>
            ))}
            {pages1.map((page) => (
              <>
                {page.badge && page.number !== 0 && (
                  <Button
                    key={page}
                    // color={colors.background.white}

                    onClick={() => navigate(`/${page.link}`)}
                    sx={{
                      mx: 2,
                      color: colors.background.tertiary,
                      display: "block",
                      textTransform: "none",
                      fontFamily: "Campton",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    <Badge badgeContent={page.number} max={10} color="primary">
                      <Typography
                        sx={{
                          borderBottomWidth:
                            location.pathname === `${page.url}` ? 1 : 0,
                          borderBottomColor: "#DEA449",
                        }}
                      >
                        {page.name}
                      </Typography>
                    </Badge>
                  </Button>
                )}
              </>
            ))}
            {pages2.map((page) => (
              <>
                {page.badge ? (
                  page.number !== 0 && (
                    <Button
                      key={page}
                      // color={colors.background.white}

                      onClick={() => navigate(`/${page.link}`)}
                      sx={{
                        mx: 2,
                        color: colors.background.tertiary,
                        display: "block",
                        textTransform: "none",
                        fontFamily: "Campton",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      <Badge
                        badgeContent={page.number}
                        max={10}
                        color="primary"
                      >
                        {page.name}
                      </Badge>
                    </Button>
                  )
                ) : (
                  <Button
                    key={page.name}
                    // color={colors.background.white}

                    onClick={() => navigate(`/${page.link}`)}
                    sx={{
                      mx: 2,
                      color: colors.background.tertiary,
                      display: "block",
                      textTransform: "none",
                      fontFamily: "Campton",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    {/* {location.pathname} */}
                    <Typography
                      sx={{
                        borderBottomWidth:
                          location.pathname === `${page.url}` ? 1 : 0,
                        borderBottomColor: "#DEA449",
                      }}
                    >
                      {page.name}
                    </Typography>
                  </Button>
                )}
              </>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={localization.Menu.locationEvent[language]}>
              <StyledIconButton
                style={{
                  marginRight: 8,
                  // backgroundColor: colors.background.main,
                  // color: colors.background.tertiary,
                  // border: `solid 1px ${colors.background.outline} `,
                  // padding: "8px",
                }}
                onClick={() => navigate("/events")}
                sx={{ p: 0 }}
              >
                <Search fontSize="14px" />
              </StyledIconButton>
            </Tooltip>
            <Tooltip title={localization.Menu.gtp[language]}>
              <StyledIconButton
                style={{
                  marginRight: 8,
                  // backgroundColor: colors.background.main,
                  // color: colors.background.tertiary,
                  // border: `solid 1px ${colors.background.outline} `,
                  // padding: "8px",
                }}
                onClick={() => {
                  window.open(portalLoginUrl, "_self");
                }}
                sx={{ p: 0 }}
              >
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                <BiUser />
              </StyledIconButton>
            </Tooltip>
            <Tooltip title={localization.Menu.locationEvent[language]}>
              <StyledIconButton
                style={{
                  marginRight: 8,
                  // backgroundColor: colors.background.main,
                  // color: colors.background.tertiary,
                  // border: `solid 1px ${colors.background.outline} `,
                  // padding: "8px",
                }}
                onClick={() => navigate("/locations")}
                sx={{ p: 0 }}
              >
                <LocationOn />
              </StyledIconButton>
            </Tooltip>
            <Tooltip title={localization.Menu.lang[language]}>
              <StyledIconButton
                style={{
                  marginRight: 8,
                  // backgroundColor: colors.background.main,
                  // color: colors.background.tertiary,
                  // border: `solid 1px ${colors.background.outline} `,
                  // padding: "8px",
                }}
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
              >
                <MdLanguage />
              </StyledIconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.code}
                  // sx={{
                  //   color: setting.code === localStorage.getItem('preferredLanguage') ? 'red' : 'black',
                  // }}
                  onClick={() => handleLanguageChange(setting.code)}
                >
                  <Typography
                    color={{
                      color:
                        setting.code === language
                          ? colors.background.secondary
                          : colors.background.outline,
                    }}
                    textAlign="center"
                  >
                    {setting.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
            <Tooltip title={localization.Menu.currency[language]}>
              <StyledIconButton
                // style={{
                //   // backgroundColor: colors.background.main,
                //   color: colors.background.tertiary,
                //   border: `solid 1px ${colors.background.outline} `,
                //   padding: "8px",
                // }}
                onClick={handleOpenCurMenu}
                sx={{ p: 0 }}
              >
                <MdCurrencyExchange />
              </StyledIconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElCur}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElCur)}
              onClose={handleCloseCurMenu}
            >
              {currencySetting.map((setting) => (
                <MenuItem
                  key={setting.code}
                  // sx={{
                  //   color: setting.code === localStorage.getItem('preferredLanguage') ? 'red' : 'black',
                  // }}
                  onClick={() => handlecurrencyChange(setting.code)}
                >
                  <Typography
                    color={{
                      color:
                        setting.code === currency
                          ? colors.background.secondary
                          : colors.background.outline,
                    }}
                    textAlign="center"
                  >
                    {setting.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
