import React, { useContext, useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import moment from "moment-timezone";

//import TextField from "@mui/material/TextField";
//import FormControlLabel from "@mui/material/FormControlLabel";
//import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  useTheme,
  //FormControl,
  IconButton,
  /* InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Tooltip,*/
} from "@mui/material";
import /*FormikPhoneField,
  FormikRadio,
  FormikSelectField,
  FormikSelectWithImageField,
  FormikSwitchField,
  FormikTextField,*/
"components/formComponents/CustomFormikFields";
import { useGetPaymentMethodsQuery } from "features/paymentPlan/paymentPlanApiSlice";
import { colors } from "constants/themeConstants";
//import { Info, InfoOutlined } from "@mui/icons-material";
import {
  useGetSpecialServiceQuery,
  useGetTicketBundlesQuery,
  useGetEventTicketQuery,
} from "features/ticket/ticketApiSlice";
//import { localization } from "constants/localalization";
import { StyledLinearProgress } from "pages/EventDetail/styles";
import { FeaturedTitle } from "components/Cards/FeaturedEventCard/Styles";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { LanguageContext } from "hooks/useLanguage";
import { CurrencyContext } from "hooks/useCurrency";
import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
import { localization } from "constants/localalization";
import { FormikSelectScheduleField } from "components/formComponents/CustomFormikFields";
import useGetDataPagination from "hooks/useGetDataPagination";
import Marquee from "react-fast-marquee";
import { useGetEventScheduleQuery } from "features/events/eventsApiSlice";
import { useGetEventQuery } from "features/events/eventsApiSlice";
import { format } from "date-fns";
import { toDate, formatInTimeZone } from "date-fns-tz";
function PriceSelect({
  price,
  ticket,
  tickets,
  eventId,
  setPrice,
  setBundle,
  setCurrency,
  currencyy,
  setTicket,
  isRecurring,
  scheduleId,
  snapshot,
}) {
  const { currency } = useContext(CurrencyContext);
  function roundToTwoDecimalPlaces(number) {
    // console.log(number);
    return Number(number.toFixed(2));
  }
  const [visible, setVisible] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const contentRef = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { language } = useContext(LanguageContext);
  const handleInfoClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };
  // useEffect(() => {
  //   setTicket(tickets[0]?.id);
  //   // setSelectedTicket(tickets[0]?.id);
  // }, []);

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  // console.log(selectedScheduleId);
  const { data: specialService, isLoading } = useGetSpecialServiceQuery({
    id: eventId,
  });
  const { data: event, isError, error } = useGetEventQuery({ id: eventId });
  console.log(event?.timezone);
  const { data } = useGetPaymentMethodsQuery();
  const theme = useTheme();
  const {
    isLoading: isLoadingSchedule,
    pagination: paginationSchedule,
    setPagination: setPaginationSchedule,
    rowCount: rowCountSchedule,
    data: schedule,
    setEventId: setEventIdSchedule,
  } = useGetDataPagination()(useGetEventScheduleQuery);
  console.log(schedule);
  useEffect(() => {
    setEventIdSchedule(eventId);
  }, []);
  useEffect(() => {
    if (schedule && schedule.length > 0) {
      setSelectedScheduleId(schedule[0].id);
      localStorage.setItem("selectedScheduleId", schedule[0].id);
    }
  }, [schedule]);
  const handleScheduleClick = (scheduleId) => {
    setSelectedScheduleId(scheduleId);
    localStorage.setItem("selectedScheduleId", scheduleId);
  };
  const [selectedScheduleId, setSelectedScheduleId] = useState();
  console.log(selectedScheduleId);
  const { data: bundles, isLoading: bundleLoading } = useGetTicketBundlesQuery({
    id: eventId,
    scheduleId: selectedScheduleId,
  });
  const { data: eventtickets, isLoading: ticketsloading } =
    useGetEventTicketQuery({
      id: eventId,
      scheduleId: selectedScheduleId,
    });
  const paymentMethods = data
    ? data.map((item) => ({
        label: item.logo_url,
        value: item.identifier,
      }))
    : [];
  console.log(tickets);
  console.log(scheduleId);

  useEffect(() => {
    const handleResizeAndScroll = () => {
      const container = contentRef.current;
      if (container) {
        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(
          container.scrollWidth > container.clientWidth + container.scrollLeft
        );
      }
    };

    const container = contentRef.current;
    if (container) {
      handleResizeAndScroll();
      container.addEventListener("scroll", handleResizeAndScroll);
      window.addEventListener("resize", handleResizeAndScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleResizeAndScroll);
        window.removeEventListener("resize", handleResizeAndScroll);
      }
    };
  }, []);

  const handleScroll = (scrollOffset) => {
    const container = contentRef.current;
    if (container) {
      container.scrollTo({
        left: container.scrollLeft + scrollOffset,
        behavior: "smooth",
      });
    }
  };
  const eventTimezone = event?.timezone.split(" ")[0];
  const formatTime = (time, eventTimezone) => {
    const utcDate = toDate(`2000-01-01T${time}Z`);
    return formatInTimeZone(utcDate, eventTimezone, "hh:mm a");
  };

  console.log(eventTimezone);

  return (
    <React.Fragment>
      {isRecurring && (
        <Grid style={{ overflowX: "auto" }} ref={contentRef}>
          <Grid spacing={2} style={{ display: "inline-flex" }}>
            {showLeftArrow && (
              <IconButton
                style={{
                  position: "absolute",
                  left: 0,
                  top: "40%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => handleScroll(-100)}
              >
                <ArrowBack />
              </IconButton>
            )}
            {schedule?.map((item, index) => {
              const startTime = formatTime(item.start_time, eventTimezone);
              const endTime = formatTime(item.end_time, eventTimezone);

              return (
                <Grid key={item?.id} item xs="auto">
                  <Box
                    onClick={() => {
                      handleScheduleClick(item?.id);
                    }}
                    sx={{
                      padding: "1.5rem 1rem 1.5rem 1rem",
                      margin: "1rem",
                      width: "14rem",
                      flexDirection: "column",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#C5C6C6",
                      cursor: "pointer",
                      "&:hover": {
                        border: `1px solid ${theme.palette.primary.main}`,
                      },
                      border: `${
                        selectedScheduleId === item?.id ? "2px" : "0px"
                      } solid ${theme.palette.primary.main}`, // Adjusted the border logic
                      borderRadius: "10px",
                    }}
                  >
                    <Marquee speed={30}>
                      <Typography
                        marginRight={1}
                        fontWeight={600}
                        color={colors.background.tertiary}
                        fontFamily="Campton"
                      >
                        {item?.name}
                      </Typography>{" "}
                    </Marquee>
                    <Grid display="flex">
                      <Typography
                        marginRight={1}
                        fontWeight={600}
                        color={colors.background.tertiary}
                        fontFamily="Campton"
                      >
                        {moment(new Date(item?.start_date)).format("MMM")}
                      </Typography>
                      <Typography
                        color={colors.background.tertiary}
                        fontFamily="Campton"
                      >
                        {moment(new Date(item?.start_date)).format("DD,YYYY")}
                      </Typography>
                    </Grid>
                    <Typography
                      color={colors.background.tertiary}
                      fontFamily="Campton"
                    >
                      <Typography
                        key={index}
                        style={{
                          color: colors.background.tertiary,
                          fontFamily: "Campton",
                        }}
                      >
                        {startTime} - {endTime}
                      </Typography>
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
            {showRightArrow && (
              <IconButton
                style={{
                  position: "absolute",
                  right: 0,
                  top: "40%",
                  transform: "translateY(-50%)",
                }}
                onClick={() => handleScroll(100)}
              >
                <ArrowForward />
              </IconButton>
            )}
          </Grid>
        </Grid>
      )}
      <Typography
        color={colors.background.tertiary}
        fontFamily={"Comfortaa"}
        fontSize={"22px"}
        fontWeight={"700"}
      >
        Select a Ticket
      </Typography>

      <Grid spacing={2} container>
        {eventtickets?.map((item) => {
          if (item.is_visible && item.total_available_quantity > 0) {
            const matchingPrice = item.prices.find(
              (price) => price.currency === currency
            );
            if (matchingPrice) {
              return (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  key={item.id}
                  style={{
                    width: "auto",
                    flexShrink: "100 !important",
                  }}
                >
                  {item.sales_channel !== "DOOR_ONLY" ? (
                    <Box
                      sx={{
                        backgroundColor: colors.background.pureWhite,
                        borderRadius: "20px",
                        border:
                          ticket === item.id
                            ? `2px solid ${colors.background.secondary}`
                            : `1px solid ${colors.background.outline}`,
                        transition: "background-color 0.3s",
                        backgroundColor:
                          ticket === item.id
                            ? colors.background.secondary
                            : colors.background.pureWhite,
                        "&:hover": {
                          backgroundColor: colors.background.secondary,
                          borderRadius: "20px",
                          cursor: "pointer",
                        },
                      }}
                      padding={1}
                      width="100%"
                      onClick={() => {
                        setPrice(matchingPrice.price);
                        setCurrency(matchingPrice.currency);
                        setTicket(item.id);
                      }}
                      border="1px solid white"
                    >
                      <Grid
                        container
                        justifyContent={"center"}
                        justifyItems="center"
                        alignContent={"center"}
                        alignItems="center"
                      >
                        <Grid item xs={9}>
                          <FeaturedTitle
                            sx={{
                              color: colors.background.tertiary,
                              fontWeight: "300",
                              fontSize: "30px",
                            }}
                          >
                            {roundToTwoDecimalPlaces(matchingPrice.price) +
                              matchingPrice.currency}
                          </FeaturedTitle>
                          <Typography
                            fontFamily={"Comfortaa"}
                            fontWeight={300}
                            fontSize="14px"
                            sx={{
                              color: colors.background.tertiary,
                            }}
                          >
                            {item.name[language]}
                          </Typography>
                        </Grid>
                        <Grid
                          justifyContent={"center"}
                          justifyItems="center"
                          alignContent={"center"}
                          alignItems="center"
                          item
                          xs={3}
                        >
                          <IconButton
                            aria-label="filter"
                            sx={{
                              marginRight: 2,
                              backgroundColor: colors.background.primary,
                              color: colors.background.tertiary,
                              "&:hover": {
                                backgroundColor: colors.background.tertiary,
                                color: colors.background.primary,
                              },
                              ml: 1,
                            }}
                          >
                            <AiOutlineShoppingCart
                              sx={{
                                height: 32,
                                width: 32,
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        borderRadius: "20px",
                        border: `1px solid ${colors.background.outline}`,
                        transition: "background-color 0.3s",
                        "&:hover": {
                          backgroundColor: colors.background.secondary,
                          borderRadius: "20px",
                          cursor: "pointer",
                        },
                      }}
                      padding={1}
                      width="100%"
                    >
                      <Grid
                        container
                        justifyContent={"center"}
                        justifyItems="center"
                        alignContent={"center"}
                        alignItems="center"
                      >
                        <Grid item xs={9}>
                          <FeaturedTitle
                            sx={{
                              color: colors.background.tertiary,
                              fontWeight: "300",
                              fontSize: "30px",
                            }}
                          >
                            {item?.prices.length === 0
                              ? "FREE"
                              : roundToTwoDecimalPlaces(matchingPrice.price) +
                                matchingPrice.currency}
                          </FeaturedTitle>
                          <Typography
                            fontFamily={"Comfortaa"}
                            fontWeight={300}
                            fontSize="14px"
                            sx={{
                              color: colors.background.tertiary,
                            }}
                          >
                            {item.name[language]}
                          </Typography>
                        </Grid>
                        <Grid
                          justifyContent={"center"}
                          justifyItems="center"
                          alignContent={"center"}
                          alignItems="center"
                          item
                          xs={3}
                        >
                          <Typography
                            fontFamily={"Comfortaa"}
                            fontWeight={500}
                            fontSize="14px"
                            sx={{
                              color: colors.background.tertiary,
                            }}
                          >
                            Door Only
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>
              );
            }
          }
          return null; // Return null if the item is not visible or total available quantity is 0
        })}
      </Grid>

      {bundles?.length > 0 && (
        <Grid
          container
          mt={4}
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <Typography
            color={colors.background.tertiary}
            fontFamily={"Comfortaa"}
            fontSize={"22px"}
            fontWeight={"700"}
          >
            {localization.Titles.bundle[language]}
          </Typography>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {bundles?.map((item) => {
                if (item.is_visible && item.total_available_quantity > 0) {
                  const matchingPrice = item.prices.find(
                    (price) => price.currency === currency
                  );
                  if (matchingPrice) {
                    return (
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        key={item.id}
                        style={{
                          width: "auto",
                          flexShrink: "100 !important",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: colors.background.pureWhite,
                            borderRadius: "20px",
                            border:
                              ticket === item.id
                                ? `2px solid ${colors.background.secondary}`
                                : `1px solid ${colors.background.outline}`,
                            transition: "background-color 0.3s",
                            backgroundColor:
                              ticket === item.id
                                ? colors.background.secondary
                                : "",
                            "&:hover": {
                              backgroundColor: colors.background.secondary,
                              borderRadius: "20px",
                              cursor: "pointer",
                            },
                          }}
                          padding={1}
                          width="100%"
                          border="1px solid white"
                          onClick={() => {
                            setPrice(matchingPrice.price);
                            setCurrency(matchingPrice.currency);
                            setBundle(true);
                            setTicket(item.id);
                          }}
                        >
                          <Grid
                            container
                            justifyContent={"center"}
                            justifyItems="center"
                            alignContent={"center"}
                            alignItems="center"
                          >
                            <Grid item xs={9}>
                              <FeaturedTitle
                                sx={{
                                  color: colors.background.tertiary,
                                  fontWeight: "300",
                                  fontSize: "30px",
                                }}
                              >
                                {roundToTwoDecimalPlaces(matchingPrice.price) +
                                  matchingPrice.currency}
                              </FeaturedTitle>
                              <Typography
                                fontFamily={"Comfortaa"}
                                fontWeight={300}
                                fontSize="14px"
                                sx={{
                                  color: colors.background.tertiary,
                                }}
                              >
                                {item.name[language]}
                              </Typography>
                            </Grid>
                            <Grid
                              justifyContent={"center"}
                              justifyItems="center"
                              alignContent={"center"}
                              alignItems="center"
                              item
                              xs={3}
                            >
                              <IconButton
                                aria-label="filter"
                                sx={{
                                  marginRight: 2,
                                  backgroundColor: colors.background.primary,
                                  color: colors.background.tertiary,
                                  "&:hover": {
                                    backgroundColor: colors.background.tertiary,
                                    color: colors.background.primary,
                                  },
                                  ml: 1,
                                }}
                              >
                                <AiOutlineShoppingCart
                                  sx={{
                                    height: 32,
                                    width: 32,
                                  }}
                                />
                              </IconButton>
                            </Grid>

                            {visible && (
                              <Grid
                                justifyContent={"center"}
                                justifyItems="center"
                                alignContent={"center"}
                                alignItems="center"
                                item
                                xs={12}
                              >
                                {item?.rules?.map((rule) => {
                                  return (
                                    <Typography
                                      fontFamily={"Comfortaa"}
                                      fontWeight={500}
                                      fontSize="14px"
                                      sx={{
                                        color: colors.background.tertiary,
                                      }}
                                      textAlign={"center"}
                                    >
                                      {rule?.included_quantity +
                                        "x " +
                                        rule?.ticket?.name[language]}
                                    </Typography>
                                  );
                                })}
                              </Grid>
                            )}
                            {visible ? (
                              <KeyboardDoubleArrowUp
                                onClick={() => setVisible(false)}
                                sx={{
                                  color: colors.background.tertiary,
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <KeyboardDoubleArrowDown
                                onClick={() => setVisible(true)}
                                sx={{
                                  color: colors.background.tertiary,
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    );
                  }
                }
                return null;
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default PriceSelect;
